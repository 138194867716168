import React, { createContext, useReducer } from 'react';

const initialState = {
  test: 'check',
  cart: {
    totalPrice: 0,
  },
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'UPDATE_CART':
        return {
          ...state,
          cart: {
            ...state.cart,
            totalPrice: action.data,
          },
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
